import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.png"
import MediaMainBanner from "./assets/MediaMainBanner.webp"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.png"

import styles from "./styles.module.scss"

export const mainBanner = {
	banner: {
		mediaContent: (
			<>
				<img
					className={styles.mainBanner__desktop}
					src={MediaMainBanner}
					alt="Частный бар Сезоны"
					loading="lazy"
				/>
				<img
					className={styles.mainBanner__tablet}
					src={MediaMainBannerMobile}
					alt="Частный бар Сезоныe"
					loading="lazy"
				/>
			</>
		),
		desktopBanner: (
			<img
				src={DesktopMediaMainBanner}
				alt="Частный бар Сезоны"
				loading="lazy"
			/>
		),
		logo: (
			<img
				className={styles.logo}
				src={MainBannerLogo}
				alt="Логотип"
				loading="lazy"
			/>
		),
		quote: (
			<>
				Из маркетолога в рестораторы.{" "}
				<span className={styles.heading}>
					Кейс бара <span className={styles.quote}>«Сезоны»</span>
				</span>
			</>
		),
	},
	heading:
		"«Открывать очередной ресторан à la carte нам было неинтересно» — история частного бара для камерных мероприятий",
	textarea: (
		<p className={styles.margin0}>
			Повар-кондитер по профессии, музыкант в душе, маркетолог в прошлом и
			сооснователь нетипичного ресторанного проекта сейчас. Константин Кудобаев
			— человек творческий, увлечённый и вдохновляющий. Костя рассказал, как
			пришёл в ресторанный бизнес и как с партнёрами они из частных мероприятий
			сделали прибыльный продукт, не похожий на привычные банкеты.
		</p>
	),
	video: {
		src: "https://www.youtube.com/embed/FKZQ9psdN6A",
		textarea: (
			<p className={styles.textarea}>
				Даже профессионалы терпят <wbr /> поражение — пробуй, анализируй <wbr />{" "}
				и извлекай пользу
			</p>
		),
	},
}
