import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"

import PromptIconOne from "./assets/PromptIconOne.png"
import PromptIconTwo from "./assets/PromptIconTwo.png"
import PromptIconThree from "./assets/PromptIconThree.png"
import PromptIconFour from "./assets/PromptIconFour.png"
import PromptIconFive from "./assets/PromptIconFive.png"
import PromptIconSix from "./assets/PromptIconSix.png"
import PromptIconSeven from "./assets/PromptIconSeven.png"
import PromptIconEight from "./assets/PromptIconEight.png"
import PromptIconNine from "./assets/PromptIconNine.png"

import MediaContentOne from "./assets/MediaContentOne.webp"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"
import MediaContentFour from "./assets/MediaContentFour.webp"
import MediaContentFive from "./assets/MediaContentFive.webp"
import MediaContentSix from "./assets/MediaContentSix.webp"
import MediaContentSeven from "./assets/MediaContentSeven.webp"
import MediaContentEight from "./assets/MediaContentEight.webp"
import MediaContentNine from "./assets/MediaContentNine.webp"
import MediaContentTen from "./assets/MediaContentTen.webp"
import MediaContentEleven from "./assets/MediaContentEleven.webp"
import MediaContentTwelve from "./assets/MediaContentTwelve.webp"
import MediaContentThreeteen from "./assets/MediaContentThreeteen.webp"
import MediaContentFourteen from "./assets/MediaContentFourteen.webp"

import FancyMediaContentOne from "./assets/FancyMediaContentOne.webp"
import FancyMediaContentTwo from "./assets/FancyMediaContentTwo.webp"
import FancyMediaContentThree from "./assets/FancyMediaContentThree.webp"
import FancyMediaContentFour from "./assets/FancyMediaContentFour.webp"
import FancyMediaContentFive from "./assets/FancyMediaContentFive.webp"
import FancyMediaContentSix from "./assets/FancyMediaContentSix.webp"
import FancyMediaContentSeven from "./assets/FancyMediaContentSeven.webp"
import FancyMediaContentEight from "./assets/FancyMediaContentEight.webp"
import FancyMediaContentNine from "./assets/FancyMediaContentNine.webp"
import FancyMediaContentTen from "./assets/FancyMediaContentTen.webp"
import FancyMediaContentEleven from "./assets/FancyMediaContentEleven.webp"
import FancyMediaContentTwelve from "./assets/FancyMediaContentTwelve.webp"
import FancyMediaContentThirtTeen from "./assets/FancyMediaContentThirtTeen.webp"
import FancyMediaContentFourTeen from "./assets/FancyMediaContentFourTeen.webp"

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading: "В 30 лет я почувствовал, что хочу чего-то ещё",
		textarea: (
			<>
				Я&nbsp;родился в&nbsp;Петербурге и&nbsp;значительную часть жизни
				проработал в&nbsp;большой организации, где занимался маркетингом.
				В&nbsp;30&nbsp;лет я&nbsp;почувствовал лёгкий зуд&nbsp;&mdash;
				захотелось попробовать себя в&nbsp;каких-то других областях. У&nbsp;меня
				были личные накопления, я&nbsp;нашёл бизнес-партнёров,
				и&nbsp;мы&nbsp;открыли первое заведение&nbsp;&mdash; Union Bar
				на&nbsp;Литейном проспекте.
			</>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentOne
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={FancyMediaContentOne}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentOne}
							alt="Константин Кудобаев"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descOne)}>
					Постепенно мне стало скучно и захотелось попробовать себя в работе в
					полях
				</p>
			</div>
		),
	},
	{
		textarea: (
			<>
				Ожиданий было немного.{" "}
				<strong>
					Я&nbsp;хотел получить входной билет в&nbsp;ресторанный бизнес,
				</strong>{" "}
				осмотреться и&nbsp;принимать решения по&nbsp;обстоятельствам.
				Я&nbsp;попал в&nbsp;хорошую команду, учился и&nbsp;пытался помогать, чем
				мог. После были ещё проекты, в&nbsp;которых я&nbsp;участвовал
				исключительно как учредитель. Но&nbsp;постепенно мне стало скучно
				и&nbsp;захотелось попробовать себя в&nbsp;работе в&nbsp;полях. Поэтому,
				когда подвернулась площадка будущих &laquo;Сезонов&raquo;,
				я&nbsp;вызвался получить самостоятельный опыт управления.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTwo)}>
				<img
					className={classNames(styles.icon, styles.icon__two)}
					src={PromptIconTwo}
					alt="Иконка 2"
					loading="lazy"
				/>
				<p>
					Я был инвестором, официантом, барбеком, чистил овощи — пробовал всё
				</p>
			</div>
		),
	},
	{
		heading: "Предпринимателю нужно во всём разбираться",
		textarea: (
			<>
				Сначала я был просто инвестором и наблюдал за процессами. Потом выходил
				работать официантом, барбеком, чистил овощи — в общем, пробовал всё, что
				только можно, и продолжаю это делать. Здесь мне пригодилось образование
				по специальности «пекарь-кондитер», которое в своё время я считал
				бессмысленным. Но благодаря ему я понимаю, например, в технике пищевой
				безопасности, технологических картах и других вопросах.
			</>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentTwo)}>
				<Fancybox>
					<a
						data-fancybox="image2"
						href={FancyMediaContentTwo}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentTwo}
							alt="Работа за барной стойкой"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descTwo)}>
					Костя за барной стойкой во время мероприятия
				</p>
			</div>
		),
		textarea2: (
			<>
				Предпринимательство — такая штука: ты должен разбираться во всём хотя бы
				по чуть-чуть. Тогда в экстренной ситуации ты сможешь решить любые
				проблемы. А проблемы случаются. У нас, например, периодически без
				предупреждения отключают электричество, сбрасывают снег с крыши,
				ремонтируют фасад и так далее.{" "}
				<strong>
					Я чувствую себя героем игры GTA, у которого каждый день новые задания.
				</strong>{" "}
				Сегодня я везу что-то на газели, завтра разыскиваю какой-нибудь редкий
				продукт, послезавтра ещё что-нибудь. И в конце дня миссия либо
				провалена, либо выполнена.
			</>
		),
	},
	{
		heading: "Мы долго искали концепцию",
		textarea: (
			<>
				Работать à la carte в нашем помещении было неинтересно: рядом
				Рубинштейна — на завтраки, обеды и ужины люди ходят именно туда. А на
				нашу прекрасную набережную Фонтанки идут уже после, чтобы прогуляться.
				Тогда мы задумались — чего нам самим не хватает? И поняли — места, где
				мы бы могли что-нибудь отметить узким кругом, чтобы в зале не было
				посторонних, чтобы были свои напитки, еда и оформление зала.
			</>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentThree
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image3"
						href={FancyMediaContentThree}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentThree}
							alt="Атмосфера бара Сезоны"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descThree)}>
					Камерная атмосфера — одна из главных особенностей «Сезонов»
				</p>
			</div>
		),
	},
	{
		textarea: (
			<>
				Когда тебе чего-то не&nbsp;хватает, скорее всего, этого не&nbsp;хватает
				кому-то ещё. <strong>И&nbsp;значит, это свободная ниша.</strong> Так
				мы&nbsp;нашли свою нишу, которую на&nbsp;тот момент, шесть лет назад,
				занимали банкетные залы в&nbsp;классическом их&nbsp;понимании.
				И&nbsp;решили сделать альтернативу&nbsp;&mdash; подтянуть частные
				мероприятия до&nbsp;уровня современного гастрономического рынка.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconThree)}>
				<img
					className={classNames(styles.icon, styles.icon__three)}
					src={PromptIconThree}
					alt="Иконка 3"
					loading="lazy"
				/>
				<p className={styles.contentSmallHide}>
					То, что тебе не хватает <wbr /> — это свободная ниша
				</p>
				<p className={styles.contentSmallShow}>
					То, что тебе не хватает — это <wbr /> свободная ниша
				</p>
			</div>
		),
	},
	{
		heading:
			"Чтобы создать нужную атмосферу, мы постоянно что-то совершенствуем ",
		textarea: (
			<>
				<span className={styles.block}>
					До нас здесь уже был ресторан со свежим ремонтом — его мы не
					переделывали, но придумали много разных ноу-хау:
				</span>

				<ul className={styles.ul}>
					<li>
						Столы-трансформеры с телескопическими ножками для низкой и высокой
						посадки.
					</li>
					<li>
						Кодовый замок на входной двери, чтобы люди с улицы не беспокоили
						гостей мероприятия.
					</li>
					<li>
						ТВ-приставка и проектор, чтобы гости, которые не смогли приехать,
						побывали на мероприятии дистанционно.
					</li>
					<li>И много других нюансов, которые мы до сих пор совершенствуем.</li>
				</ul>

				<span className={styles.block}>
					<strong>
						Постоянные маленькие улучшения создают ощущение, что проект живёт
						и&nbsp;развивается.
					</strong>{" "}
					Идеи черпаем, изучая рынок. Например, в&nbsp;ресторанной тусовке есть
					такая популярная штука сабраж&nbsp;&mdash; способ открыть бутылку
					шампанского с&nbsp;помощью сабли. И&nbsp;мы&nbsp;начали делать это
					на&nbsp;свадьбах&nbsp;&mdash; ведь как романтично: жених срубает
					пробку саблей для своей невесты. Гости за&nbsp;него болеют
					и&nbsp;очень радуются, когда всё получается, и&nbsp;эти живые эмоции
					потом остаются на&nbsp;всю жизнь на&nbsp;фотографиях и&nbsp;видео.
				</span>
			</>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentFour)}>
				<Fancybox>
					<a
						data-fancybox="image4"
						href={FancyMediaContentFour}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFour}
							alt="Бар для камерных мероприятий"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFour)}>
					Наш бармен показывает жениху, как правильно открыть бутылку саблей
				</p>
			</div>
		),
		textarea2: (
			<>
				Ещё один продукт, который мы, может, и не первые придумали, но
				адаптировали под себя — <strong>мастер-классы по бару.</strong> Сейчас
				модно быть подкованным в вине, а коктейльная история остаётся по ту
				сторону барной стойки. Мы решили это изменить — рассказываем гостям
				интересные факты из коктейльной индустрии, затем готовим для них
				необычные напитки и даём возможность самостоятельно потрясти шейки.
			</>
		),
	},
	{
		mediaContent: (
			<div className={styles.mediaContent__container}>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image5"
							href={FancyMediaContentFive}
							className={classNames(styles.media)}
						>
							<img src={MediaContentFive} alt="Работа бармена" loading="lazy" />
						</a>
					</Fancybox>

					<p className={classNames(styles.desc, styles.descFive)}>
						Рассказываем гостям факты из коктейльной индустрии
					</p>
				</div>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image6"
							href={FancyMediaContentSix}
							className={classNames(styles.media)}
						>
							<img
								src={MediaContentSix}
								alt="Работа бармена на мероприятиях"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p className={classNames(styles.desc, styles.descSix)}>
						Готовим для них необычные напитки
					</p>
				</div>
			</div>
		),
	},
	{
		textarea: (
			<>
				С&nbsp;февраля 2022 года мы&nbsp;расширились до&nbsp;второго заведения
				на&nbsp;улице Писарева с&nbsp;вместительностью до&nbsp;100
				человек&nbsp;&mdash; это в&nbsp;2,5 раза больше, чем на&nbsp;Фонтанке.
				Мы&nbsp;постарались взять всё лучшее, что успели наработать
				в&nbsp;первых &laquo;Сезонах&raquo;, потому что этот проект для нас
				эталонный&nbsp;&mdash; он&nbsp;работает как часы. Но&nbsp;на&nbsp;новом
				месте другие правила игры, поэтому нам нужно заново завоёвывать
				репутацию.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFour)}>
				<img
					className={classNames(styles.icon, styles.icon__four)}
					src={PromptIconFour}
					alt="Иконка 4"
					loading="lazy"
				/>
				<p className={styles.contentSmallHide}>
					Столы-трансформеры, <wbr /> кодовый замок, проектор <wbr /> — в
					«Сезонах» много <wbr /> ноу-хау
				</p>
				<p className={styles.contentSmallShow}>
					Столы-трансформеры, кодовый <wbr /> замок, проектор — в «Сезонах»{" "}
					<wbr /> много ноу-хау
				</p>
			</div>
		),
	},
	{
		heading:
			"Чтобы заниматься частными мероприятиями, важно понимать их особенности",
		textarea: (
			<>
				В&nbsp;основном у&nbsp;нас проводят свадьбы&nbsp;&mdash; мероприятия,
				которые большинство устраивает впервые. Мы&nbsp;помогаем женихам
				и&nbsp;невестам сориентироваться в&nbsp;свадебном рынке, где много
				предложений по&nbsp;еде, платьям, украшениям, развлечениям,
				и&nbsp;предлагаем подобрать всех специалистов. Нам важно, с&nbsp;кем
				мы&nbsp;работаем.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFive)}>
				<img
					className={classNames(styles.icon, styles.icon__five)}
					src={PromptIconFive}
					alt="Иконка 5"
					loading="lazy"
				/>
				<p>Нам важно, с кем мы работаем</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentSeven
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image7"
						href={FancyMediaContentSeven}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSeven}
							alt="Бар для свадебных мероприятий"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSeven)}>
					Помогаем женихам и невестам сориентироваться в свадебном рынке
				</p>
			</div>
		),
		textarea2: (
			<>
				Для гостей нет границ, где заканчиваются услуги ресторана
				и&nbsp;начинаются услуги подрядчиков. И&nbsp;если было некрасивое
				оформление, получились плохие фотографии или была скучная
				музыка&nbsp;&mdash; гости запоминают заведение как место, где было
				средненько.{" "}
				<strong>
					Важно просто по-человечески любить гостей и&nbsp;стараться
					соответствовать их&nbsp;ожиданиям.
				</strong>{" "}
				В&nbsp;работе &agrave;&nbsp;la&nbsp;carte ресторан выбирает какую-то
				концепцию и&nbsp;работает на&nbsp;аудиторию, которую эта концепция
				привлекает. А&nbsp;к&nbsp;нам люди приходят по&nbsp;особым случаям,
				их&nbsp;затраты существенно выше, и&nbsp;за&nbsp;них они ожидают
				получить соответствующий своим представлениям сервис.
			</>
		),
	},
	{
		heading:
			"Даже профессионалы терпят поражение, главное — пробовать, анализировать и извлекать пользу",
		textarea: (
			<>
				За&nbsp;шесть лет существования &laquo;Сезонов&raquo; попробовали себя
				в&nbsp;разных областях: и&nbsp;в&nbsp;работе по&nbsp;меню
				на&nbsp;различных мероприятиях, и&nbsp;как буфет в&nbsp;театрах,
				на&nbsp;выставках и&nbsp;так далее. И, конечно, терпели неудачи.
				Но&nbsp;чем больше пробуешь, тем выше вероятность, что что-нибудь
				выстрелит. А&nbsp;если сидеть на&nbsp;месте и&nbsp;думать, что ничего
				не&nbsp;получится&nbsp;&mdash; ничего не&nbsp;получится.
			</>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent__container,
					styles.mediaContent__containerSecond
				)}
			>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image8"
							href={FancyMediaContentEight}
							className={classNames(styles.media)}
						>
							<img
								src={MediaContentEight}
								alt="Сервировка в баре"
								loading="lazy"
							/>
						</a>
					</Fancybox>

					<p className={classNames(styles.desc, styles.descEight)}>
						Сервировка в баре
					</p>
				</div>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image9"
							href={FancyMediaContentNine}
							className={classNames(styles.media)}
						>
							<img
								src={MediaContentNine}
								alt="Доставка еды на выездное мероприятие"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p className={classNames(styles.desc, styles.descNine)}>
						Доставка еды на выездное мероприятие
					</p>
				</div>
			</div>
		),
		textarea2: (
			<>
				Главное, когда не&nbsp;срабатывает,&nbsp;&mdash; анализировать.
				И&nbsp;первый вопрос, на&nbsp;который нужно ответить самому
				себе,&nbsp;&mdash; интересно&nbsp;ли мне этим заниматься. Если
				я&nbsp;потерпел неудачу, но&nbsp;получил удовлетворение
				от&nbsp;процесса, то, скорее всего, стоит попробовать ещё раз.
				А&nbsp;если мне не&nbsp;понравилось какое-то начинание, то, наверное,
				и&nbsp;продолжать не&nbsp;стоит. Если говорить о&nbsp;заведениях
				в&nbsp;целом, то&nbsp;без ложной скромности неудачных у&nbsp;нас нет.
				А&nbsp;что касается отдельных проектов&nbsp;&mdash; начинаний, можно
				найти много примеров того, что не&nbsp;получилось. В&nbsp;какой-то
				момент <strong>мы&nbsp;придумали способ привлекать туристов</strong>
				&nbsp;&mdash; рассказывать о&nbsp;русской кухне. Нашли редких
				специалистов, разработали большую программу, отдельное меню,
				презентационные материалы. Потратили на&nbsp;всё около
				200&nbsp;000&nbsp;рублей, собрали игроков туристического рынка,
				но&nbsp;ничего не&nbsp;выиграли.
			</>
		),
	},
	{
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSix)}>
				<img
					className={classNames(styles.icon, styles.icon__six)}
					src={PromptIconSix}
					alt="Иконка 6"
					loading="lazy"
				/>
				<p>Чем больше пробуешь, тем выше вероятность, что выстрелит</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentTen)}>
				<Fancybox>
					<a
						data-fancybox="image10"
						href={FancyMediaContentTen}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentTen}
							alt="Ресторанное меню"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descTen)}>
					Мы работаем только с именитыми шефами
				</p>
			</div>
		),
		textarea2: (
			<>
				Ещё один пример. Мы&nbsp;работаем по&nbsp;депозиту, и&nbsp;часто это
				бывает невыгодно для компаний до&nbsp;10&nbsp;человек&nbsp;&mdash; суммы
				аванса хватает на&nbsp;большее количество еды, чем нужно. Для таких
				случаев мы&nbsp;сделали дорогое меню&nbsp;&mdash; нашли лучших шефов
				Петербурга, провели качественную фуд-съёмку. Но&nbsp;за&nbsp;всё время
				продали это меню два раза и&nbsp;совершенно случайно. То&nbsp;есть
				фактически ничего не&nbsp;отбили.
				<br />
				<br />
				<strong>
					Из&nbsp;всех промахов мы&nbsp;стараемся извлечь пользу.
				</strong>{" "}
				Например, мы&nbsp;подняли уровень кухни и&nbsp;теперь работаем только
				с&nbsp;именитыми шефами&nbsp;&mdash; творческими профессиональными
				людьми, которыми сами вдохновляемся. В&nbsp;этом сезоне на&nbsp;кухне
				Дима Савельев (Buddha-Bar, Il&nbsp;Lago dei Cigni, Sixty Four)
				и&nbsp;Максим Соколов (Sixty Four, Барслона),
				а&nbsp;за&nbsp;баром&nbsp;&mdash; один из&nbsp;прекраснейших бартендеров
				этого города Семён Мосиенко (The Buddy Cafe).
			</>
		),
	},
	{
		heading: "В реальности не всё идёт, как хотелось бы",
		textarea: (
			<>
				В&nbsp;основном наши вкусы совпадают с&nbsp;предпочтениями гостей,
				но&nbsp;бывают исключения. И&nbsp;когда на&nbsp;выходе мы&nbsp;получаем
				тот самый банкетный зал, от&nbsp;которого старались отстроиться,
				становится не&nbsp;по&nbsp;себе и&nbsp;кажется, что мы&nbsp;сделали
				что-то не&nbsp;так. Когда понимаешь, что твоя идея развивается иначе,
				чем ты&nbsp;себе представлял, и&nbsp;когда это переходит грань того,
				с&nbsp;чем ты&nbsp;готов мириться,&nbsp;&mdash; хочется сдаться.{" "}
				<strong>
					А&nbsp;ещё бывает тяжело справляться с&nbsp;вызовами действительности.
				</strong>{" "}
				Я&nbsp;отвечаю за&nbsp;продукт, сотрудников, гостей. Люди смотрят
				на&nbsp;меня, верят, ждут, какое&nbsp;же решение
				я&nbsp;им&nbsp;предложу. А&nbsp;мне самому в&nbsp;такие моменты хочется
				получить от&nbsp;кого-то совет.
				<br />
				<br />
				Важно просто делать правильные выводы. В&nbsp;случае с&nbsp;развитием
				идеи&nbsp;&mdash; о&nbsp;том, как быть более убедительными для гостей
				и&nbsp;сделать так, чтобы они сами хотели современный продукт.
				В&nbsp;случае с&nbsp;непредвиденными сложностями&nbsp;&mdash;
				о&nbsp;том, что нового можно создать в&nbsp;этих обстоятельствах.
				Например, в&nbsp;пандемию мы&nbsp;не&nbsp;могли заниматься основной
				деятельностью и&nbsp;искали другие возможности для заработка. Многие
				рестораны начали работать на&nbsp;доставку&nbsp;&mdash; нишу заполнили
				высококлассные игроки, и&nbsp;нам туда лезть было неинтересно.
				Мы&nbsp;переиграли этот тренд на&nbsp;свой лад.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSeven)}>
				<img
					className={classNames(styles.icon, styles.icon__seven)}
					src={PromptIconSeven}
					alt="Иконка 7"
					loading="lazy"
				/>
				<p>В пандемию мы организовали доставку праздничной еды</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentEleven
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image11"
						href={FancyMediaContentEleven}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentEleven}
							alt="Иммерсивный театр в баре"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descEleven)}>
					«Сезоны» первыми в сегменте начали работать с иммерсивным театром в
					формате свадеб
				</p>
			</div>
		),
		textarea2: (
			<>
				В&nbsp;пандемию люди всё равно продолжали что-то отмечать не&nbsp;выходя
				из&nbsp;дома. Мы&nbsp;придумали организовать доставку именно праздничной
				еды. К&nbsp;2021 году такое обслуживание переросло в&nbsp;полноценный
				продукт&nbsp;&mdash; кейтеринг. В&nbsp;2022 году мы&nbsp;пошли дальше
				и&nbsp;<strong>разработали формат барного кейтеринга</strong>
				&nbsp;&mdash; большой выездной бар с&nbsp;оборудованием, посудой,
				стойкой. Ещё есть формат экспресс: когда фирменные коктейли
				в&nbsp;крафтовых бутылках отдаёт бармен&nbsp;&mdash; показывает,
				рассказывает, а&nbsp;дальше гости сами играют в&nbsp;коктейльный fancy
				бар. Это интересная концепция для маленького бюджетного или удалённого
				ивента.
			</>
		),
	},
	{
		heading: (
			<>
				<span className={classNames(styles.block, styles.contentTabletHide)}>
					Для бизнеса <wbr /> главное — любовь <wbr /> и маркетинг, а для{" "}
					<wbr /> предпринимателя — <wbr /> рефлексия и чувство <wbr /> юмора
				</span>
				<span className={classNames(styles.block, styles.contentTabletShow)}>
					Для бизнеса главное — любовь <wbr /> и маркетинг, а для
					предпринимателя <wbr /> — рефлексия и чувство юмора
				</span>
			</>
		),
		textarea: (
			<>
				С&nbsp;самого детства я&nbsp;увлекаюсь музыкой, играл на&nbsp;бас-гитаре
				в&nbsp;нескольких группах. Это во&nbsp;мне породило самое главное
				чувство&nbsp;&mdash; я&nbsp;знаю, что значит получать удовольствие
				от&nbsp;того, чем занимаешься и&nbsp;создаёшь.{" "}
				<strong>
					Предприниматель должен бесконечно любить своё дело и&nbsp;уметь его
					продвигать
				</strong>
				&nbsp;&mdash; красиво и&nbsp;вдохновенно о&nbsp;нём рассказывать, чтобы
				находить тех, кто готов за&nbsp;это дело платить. Когда предприниматель
				не&nbsp;любит&nbsp;то, чем занимается, это всем очевидно и&nbsp;платить
				ему не&nbsp;хочется.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconEight)}>
				<img
					className={classNames(styles.icon, styles.icon__eight)}
					src={PromptIconEight}
					alt="Иконка 8"
					loading="lazy"
				/>
				<p>
					Рефлексия — движущая <wbr /> сила
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentTwelve)}
			>
				<Fancybox>
					<a
						data-fancybox="image12"
						href={FancyMediaContentTwelve}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentTwelve}
							alt="Работа бара"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descTwelve)}>
					Умей вдохновенно рассказывать о своём деле, чтобы находить тех, кто
					готов за это платить
				</p>
			</div>
		),
		textarea2: (
			<>
				Кроме самого дела, нужно любить и&nbsp;уважать связанных с&nbsp;ним
				людей: одинаково хорошо относиться и&nbsp;к&nbsp;сотрудникам,
				и&nbsp;к&nbsp;подрядчикам, и&nbsp;к&nbsp;гостям. Только так можно
				добиться действительно качественного уровня сервиса. Когда я&nbsp;только
				начинал управлять заведением, у&nbsp;меня было много
				сомнений&nbsp;&mdash; и&nbsp;мне помогала рефлексия.
				Я&nbsp;по&nbsp;чуть-чуть во&nbsp;всём разбирался и&nbsp;постепенно
				относился спокойнее ко&nbsp;многим вещам. Рефлексия для
				меня&nbsp;&mdash; движущая сила. Ещё одна важная черта&nbsp;&mdash;
				чувство юмора. Оно спасает даже в&nbsp;критических ситуациях.
			</>
		),
	},
	{
		heading:
			"Для операционной деятельности должна быть удобная система автоматизации",
		textarea: (
			<>
				Для управления бизнесом мы&nbsp;выбрали простую и&nbsp;мобильную систему
				Quick Resto. Нас привлекло удобство интерфейса&nbsp;&mdash; это айфон
				на&nbsp;рынке систем автоматизации. Понять и&nbsp;разобраться может
				каждый, и&nbsp;не&nbsp;нужно тратить время на&nbsp;обучение персонала.
				Ответы на&nbsp;вопросы есть на&nbsp;сайте&nbsp;&mdash; каждый процесс
				изложен подробно, с&nbsp;картинками и&nbsp;видео. Если возникла
				проблема, можно обратиться в&nbsp;службу поддержки. Тебя не&nbsp;будут
				держать в&nbsp;ожидании, сразу ответят: подключатся к&nbsp;оборудованию
				и&nbsp;устранят технические неполадки.
			</>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentThreeteen
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image13"
						href={FancyMediaContentThirtTeen}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentThreeteen}
							alt="Система автоматизации бара"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descThreeteen)}>
					Quick Resto — это айфон на рынке систем автоматизации
				</p>
			</div>
		),
		textarea2: (
			<>
				Основная задача, которую мы&nbsp;решаем с&nbsp;помощью Quick
				Resto,&nbsp;&mdash; это{" "}
				<strong>
					простая и&nbsp;удобная фискализация финансовых отношений
					с&nbsp;гостями.
				</strong>{" "}
				Оплата заказов происходит за&nbsp;30&ndash;40&nbsp;секунд:
				мы&nbsp;заводим новый продукт, производим оплату, происходит
				фискализация, мы&nbsp;предоставляем финансовые документы гостям
				и&nbsp;получаем необходимую отчётность для себя, можно анализировать
				приток денежных средств. Мы&nbsp;не&nbsp;держим большой склад,
				а&nbsp;работаем с&nbsp;продуктами, которые гости выбирают
				заблаговременно, поэтому нам важна себестоимость. Мы&nbsp;заводим все
				накладные в&nbsp;Quick Resto, получаем аналитику о&nbsp;себестоимости
				и&nbsp;продажной цене и&nbsp;постоянно держим руку на&nbsp;пульсе. Это
				та&nbsp;точка, где из&nbsp;красивого дела получается бизнес.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconNine)}>
				<img
					className={classNames(styles.icon, styles.icon__nine)}
					src={PromptIconNine}
					alt="Иконка 9"
					loading="lazy"
				/>
				<p>
					Жить жизнь, которая нравится — самый большой гешефт с того, чем
					занимаешься
				</p>
			</div>
		),
	},
	{
		heading:
			"Я не бессребреник, но и очень богатым человеком стать не стремлюсь",
		textarea: (
			<>
				Недавно супруга сказала мне: &laquo;Кажется, ты&nbsp;получаешь
				удовлетворение, потому что работаешь с&nbsp;большими деньгами.
				Но&nbsp;ты&nbsp;забываешь о&nbsp;том, что эти деньги должны быть твоими
				личными&raquo;. Я&nbsp;нормально зарабатываю, бывает чуть больше, бывает
				чуть меньше&nbsp;&mdash; особенно, когда во&nbsp;что-то инвестирую.
				В&nbsp;общем, хватает, чтобы обеспечить себя, свою семью
				и&nbsp;хозяйство.
			</>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentFourteen)}
			>
				<Fancybox>
					<a
						data-fancybox="image14"
						href={FancyMediaContentFourTeen}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFourteen}
							alt="Бар Сезоны"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFourteen)}>
					Жить жизнь, которая нравится — самый большой гешефт с того, чем
					занимаешься
				</p>
			</div>
		),
		textarea2: (
			<>
				В&nbsp;первую очередь я&nbsp;зарабатываю колоссальный опыт.
				Я&nbsp;просто живу ту&nbsp;жизнь, которая мне нравится. Получаю
				удовольствие от&nbsp;того, что делаю. Общаюсь с&nbsp;людьми, которые мне
				симпатичны. И&nbsp;вот это, пожалуй, самый большой гешефт с&nbsp;того,
				чем я&nbsp;занимаюсь.
			</>
		),
	},
]

export const headingPrompt = {
	prompt: (
		<div className={classNames(styles.promptTypeOne, styles.promptIconOne)}>
			<img
				className={classNames(styles.icon, styles.icon__one)}
				src={PromptIconOne}
				alt="Иконка 1"
				loading="lazy"
			/>
			<p>
				Открыв первое заведение, я хотел <wbr /> получить входной билет <wbr />{" "}
				в ресторанный бизнес
			</p>
		</div>
	),
}
