import React, { useEffect, useState } from "react"
import classNames from "classnames"
import debounce from "lodash.debounce"

import { requestForAutoOpenRestFaster } from "../../apiRequests/callToAction"
import DefaultLayout from "../../layout/default"

import HeadingTextareaAndPrompt from "../../components/_V3/SecretPages/HeadingTextareaAndPrompt"
import MainBanner from "../../components/_V3/SecretPages/MainBanner"

import SocialNetworkBanner from "../../components/_V3/SecretPages/SocialNetworkBanner"

import ConsultationForm from "../../components/_V3/SecretPages/ConsultationForm"

import FooterCompact from "../../components/_V2/FooterCompact"

import SendPulseModal from "../../components/_V2/SendPulseModal"

import { mainBanner } from "../../pages-data/_V3/secret/sezony/MainBanner/data"
import {
	headingPrompt,
	headingTextareaAndPrompt,
} from "../../pages-data/_V3/secret/sezony/HeadingTextareaAndPrompt/data"
import { socialNetworkBanner } from "../../pages-data/_V3/secret/SocialNetworkBanner/data"
import { consultationForm } from "../../components/_V3/SecretPages/ConsultationForm/data"

import MediaMainBanner from "../../pages-data/_V3/secret/sezony/MainBanner/assets/MediaMainBanner.webp"

import pageStyles from "../../styles/_V2/pagesStyles/styles.module.scss"
import styles from "../styles/_V2/secret.module.scss"

export default function Sezony() {
	const title =
		"Секретный ингредиент бара «Сезоны»: история открытия бара для камерных мероприятий"
	const description =
		"Повар-кондитер по профессии, музыкант в душе, маркетолог в прошлом и сооснователь нетипичного ресторанного проекта сейчас. Константин Кудобаев — человек творческий, увлечённый и вдохновляющий, рассказал, как пришёл в ресторанный бизнес и как с партнёрами они из частных мероприятий сделали прибыльный продукт, не похожий на привычные банкеты."
	const url = "https://quickresto.ru/secret/sezony/"

	const metaTags = [
		{
			name: "description",
			content: description,
		},
		{
			property: "og:url",
			content: url,
		},
		{
			property: "og:title",
			content: title,
		},
		{
			property: "og:description",
			content: description,
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content:
				"https://quickresto.ru/static/MediaMainBanner-a7db3449114d88543664ce09255e983e.png",
		},
	]

	// Проверка страницы на скролл до 50% для показа попапа о подписке
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	let isHalfScrolled = false
	const handleScroll = () => {
		const pageHeight = document.body.scrollHeight
		const pageHalfHeight = pageHeight / 2

		// Если скролл больше половины страницы то показываем попап
		if (window.scrollY >= pageHalfHeight && isHalfScrolled == false) {
			setIsModalOpen(true)

			isHalfScrolled = true
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", debounce(handleScroll, 500))

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	return (
		<DefaultLayout
			title={title}
			metaTags={metaTags}
			mainWrapperClassName={classNames(
				pageStyles.pageWrapper,
				pageStyles.pageGray,
				styles.bg
			)}
			linkCanonical={url}
			isFooterEnable={false}
		>
			<MainBanner
				data={mainBanner}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.one
				)}
				bannerClassName={pageStyles.banner__sezony}
			/>

			<HeadingTextareaAndPrompt
				data={headingPrompt}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.alone,
					pageStyles.alone__section
				)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[0]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.nine
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[1]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.smallMargin,
					pageStyles.four
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[2]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.ten
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[3]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.eight
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[4]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.smallMargin,
					pageStyles.three
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[5]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.seven
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[6]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.five
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[7]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.three
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[8]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.nine
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[9]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.nine
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[10]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.six
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[11]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.nine
				)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[12]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.nine,
					pageStyles.eleven
				)}
				headingClassName={pageStyles.header__wrap}
			/>

			<SocialNetworkBanner
				data={socialNetworkBanner}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[13]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[14]}
				className={classNames(
					pageStyles.pageSection,
					pageStyles.bigMargin,
					pageStyles.nine
				)}
			/>

			<ConsultationForm
				data={consultationForm}
				className={classNames(pageStyles.pageSection)}
				request={requestForAutoOpenRestFaster}
			/>

			<FooterCompact className={styles.footer} />

			<SendPulseModal isOpen={isModalOpen} onClose={handleModalStatus} />
		</DefaultLayout>
	)
}
